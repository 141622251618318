/* eslint-disable no-undef */
import React, { useEffect } from "react"
import { BsTelephone } from "react-icons/bs"
import facebook from "../assets/icons/socials/faceboook.svg"
import instagram from "../assets/icons/socials/instagram.svg"
import linkedin from "../assets/icons/socials/linkedin.svg"
import twitter from "../assets/icons/socials/twitter.svg"
import { FaRegEnvelope } from "react-icons/fa"
import { FiMapPin } from "react-icons/fi"
import FullWidthLayout from "../components/Common/FullWidthLayout"

const Contact = () => {
  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js")
    head.appendChild(script)
  }, [])

  useEffect(() => {
    try {
      document.body.scrollTo({ top: 0, behavior: "smooth" })
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <FullWidthLayout>
      <div className="Contact--Root">
        <div className="left">
          <h3 className="title">Connect With Us</h3>
          <p className="desc">Any questions or feedback? Schedule a call with us!</p>
          <div className="contact__details">
            <div className="item">
              <p>
                <FaRegEnvelope className="icon" />
                support@thecampstack.com
              </p>
            </div>
            <div className="item">
              <p>
                <FiMapPin className="icon" />
                Los Angeles, CA
              </p>
            </div>
          </div>
          <div className="social__icons">
            <a
              className="icon__box"
              href={"https://www.facebook.com/thecampstack"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} className="socialIcon" />
            </a>
            <a
              className="icon__box"
              href={"https://www.instagram.com/officialcampstack"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} className="socialIcon" />
            </a>
            <a
              className="icon__box"
              href={"https://www.linkedin.com/company/thecampstack"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} className="socialIcon" />
            </a>
            <a className="icon__box" href={"https://www.x.com/campstack"} target="_blank" rel="noopener noreferrer">
              <img src={twitter} className="socialIcon" />
            </a>
          </div>
        </div>
        <div className="right">
          <iframe className="calendar-widget" src="https://cal.services/eric/1hr%3A-Zoom-Video-Call/nVdOyS4gb" />
        </div>
      </div>
    </FullWidthLayout>
  )
}

export default Contact
