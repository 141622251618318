import React from "react"
import WaitlistLayout from "../components/Common/WaitlistLayout"
import SparcForm from "../components/WaitlistForm/SparcForm"
import TriStateForm from "../components/WaitlistForm/TriStateForm"

const TriStatePage = () => {
  return (
    <WaitlistLayout>
      <TriStateForm withLogo={true} />
    </WaitlistLayout>
  )
}

export default TriStatePage
