import React, { useEffect } from "react"
import ReactGA from "react-ga4"
import { Routes, Route, useLocation } from "react-router-dom"
import ScrollToTop from "./helpers/ScrollToTop"
import NotFound from "./pages/NotFound"
import Home from "./pages/Home"
import WaitlistPage from "./pages/WaitlistPage"
import Contact from "./pages/Contact"
import TermsOfUse from "./pages/TermsOfUse"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import IntakeForm from "./pages/IntakeForm"
import SparcPage from "./pages/SparcPage"
import CampminderPage from "./pages/CampminderPage"
import MsmPage from "./pages/msm"
import TriStatePage from "./pages/TriStatePage"

const App = () => {
  let location = useLocation()

  useEffect(() => {
    ReactGA.initialize("G-ZTYVSMGF55")
    ReactGA.send("pageview")
  }, [location.hash])

  return (
    <ScrollToTop>
      <Routes>
        <Route exact path={`${process.env.PUBLIC_URL + "/"}`} element={<Home />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/start"}`} element={<WaitlistPage />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/sparc"}`} element={<SparcPage />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/campminder"}`} element={<CampminderPage />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/msm"}`} element={<MsmPage />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/waitlist"}`} element={<WaitlistPage />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/tri-state-24"}`} element={<TriStatePage />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/terms"}`} element={<TermsOfUse />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/privacy"}`} element={<PrivacyPolicy />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/intake"}`} element={<IntakeForm />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  )
}

export default App
