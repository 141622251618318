import React from "react"
import PropTypes from "prop-types"
import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"

export default function Layout({ children = null }) {
  return (
    <div className="RootLayout">
      <Navbar />
      <div className="containerbox">{children}</div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}
