/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
import { useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

// @todo need to refactor this to not use withRouter and use hook instead
const withRouter = Component => {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

const ScrollToTop = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.hash, location.pathname])
  return props.children
}

export default withRouter(ScrollToTop)
