import starIcon from "../../assets/icons/star.svg"
import React from "react"
import testimonialData from "./testimonialData.json"
import korey from "../../assets/images/testimonials/korey.png"
import mia from "../../assets/images/testimonials/mia.png"
import dayna from "../../assets/images/testimonials/dayna.png"

const testimonialList = testimonialData.map((r, i) => {
  r.image = [mia, korey, dayna][i]
  return r
})

export const Testimonials = () => {
  return (
    <section className="testimonialsContainer">
      <h2 className="title">Happy Clients</h2>
      <div className="testimonialsGridArea">
        <div className="content">
          {testimonialList?.map((testimonial, i) => (
            <div className="testimonial" key={i + testimonial.id}>
              <div className="testimonialName">
                <img src={testimonial.image} className="testimonialAvatar" />
                <div className="nameAndRating">
                  <h4 className="personName">{testimonial.name}</h4>
                  <div className="stars">
                    <img src={starIcon} className="starIcon" />
                    <img src={starIcon} className="starIcon" />
                    <img src={starIcon} className="starIcon" />
                    <img src={starIcon} className="starIcon" />
                    <img src={starIcon} className="starIcon" />
                  </div>
                </div>
              </div>
              <p className="description">{testimonial.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
