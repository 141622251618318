import PropTypes from "prop-types"
import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import waitlistContentBackground from "../../assets/images/waitlistContentBackground.svg"
import katy from "../../assets/images/testimonials/katy.png"
import howie from "../../assets/images/testimonials/howie.png"
import ginger from "../../assets/images/testimonials/ginger.png"
import backButton from "../../assets/icons/backButton.svg"

const WaitlistLayout = ({ children }) => {
  return (
    <Fragment>
      <div className="waitlistLayout">
        <div className="content">
          <div className="formArea">
            <Link to="/" className="backButton">
              <img src={backButton} className="icon" />
              <p className="backButtonText">Back</p>
            </Link>
            <div className="mainArea">{children}</div>
          </div>
          <div className="contentArea">
            <img src={waitlistContentBackground} className="backgroundImage" />
            <h3 className="title">Happy Campers!</h3>
            <div className="card">
              <div className="row">
                <img src={katy} className="testimonialAvatar" height="66px" />
                <div className="column">
                  <h3 className="title">Katy K.</h3>
                  <span>Director, Berkshire Soccer Academy</span>
                </div>
              </div>
              <p className="subtitle">
                “I still can’t believe I used to move data manually by clicking through downloads and moving
                spreadsheets. With The Camp Stack I have so much extra time!”
              </p>
            </div>
            <div className="card">
              <div className="row">
                <img src={howie} className="testimonialAvatar" height="66px" />
                <div className="column">
                  <h3 className="title">Howie Grossinger</h3>
                  <span>Director, Camp Robin Hood</span>
                </div>
              </div>
              <p className="subtitle">
                “Our favourite thing about The Camp Stack is that we get so much free time to add even more value for
                our staff and families.”
              </p>
            </div>
            <div className="card">
              <div className="row">
                <img src={ginger} className="testimonialAvatar" height="66px" />
                <div className="column">
                  <h3 className="title">Ginger Clare</h3>
                  <span>Director, Camp Kippewa</span>
                </div>
              </div>
              <p className="subtitle">
                “Now that we have the direct connection through The Camp Stack, we can fully levarage our technology and
                get to spend even more time doing the most meaningful part of running camp, connecting with people!”
              </p>
            </div>
          </div>
        </div>
        <div className="waitlistFooter">
          <div className="copyrightText">© Copyright 2024 - The Camp Stack</div>
          <div className="linksArea">
            <Link to="/contact" className="link">
              Contact Us
            </Link>
            <div className="verticalSeparator" />
            <Link to="/privacy" className="link">
              Privacy Policy
            </Link>
            <div className="verticalSeparator" />
            <Link to="/terms" className="link">
              Terms of Use
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

WaitlistLayout.propTypes = {
  children: PropTypes.node
}

export default WaitlistLayout
