/* eslint-disable no-undef */
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./styles/root.scss"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

import ScrollToTop from "./helpers/ScrollToTop"

const container = document.getElementById("root")
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
