import React from "react"
import FullWidthLayout from "../components/Common/FullWidthLayout"
import intakeForm from "./marketingAutomationForm.html"

const IntakeForm = () => {
  return (
    <FullWidthLayout>
      <div className="intakePage">
        <iframe className="intakeForm" srcDoc={intakeForm} />
      </div>
    </FullWidthLayout>
  )
}

export default IntakeForm
