import React from "react"
import WaitlistLayout from "../components/Common/WaitlistLayout"
import logo from "../assets/icons/logo-full-dark.svg"

import user from "../assets/icons/user.svg"
import camp from "../assets/icons/camp.svg"
import emailIcon from "../assets/icons/email.svg"
import phone from "../assets/icons/phone.svg"
import SparcForm from "../components/WaitlistForm/SparcForm"

const WaitlistPage = () => {
  return (
    <WaitlistLayout>
      <SparcForm withLogo={true} />
    </WaitlistLayout>
  )
}

export default WaitlistPage
