import React from "react"
import applications from "./applications.json"
// app icons
import sbmIcon from "../../assets/icons/appIcons/png/sbm.png"
import cmIcon from "../../assets/icons/appIcons/png/campminder.png"
import gsIcon from "../../assets/icons/appIcons/png/googlesheet.png"
import cmoIcon from "../../assets/icons/appIcons/png/camper-machine.png"
import hubspotIcon from "../../assets/icons/appIcons/png/HubSpot.png"
import mailchimpIcon from "../../assets/icons/appIcons/png/mailchimp.png"
import ccIcon from "../../assets/icons/appIcons/png/constant-contact.png"
import reIcon from "../../assets/icons/appIcons/png/blackbaud-raisers-edge.png"
import smIcon from "../../assets/icons/appIcons/png/survey-monkey.png"
import mcaIcon from "../../assets/icons/appIcons/png/my-camp-app.png"
import csIcon from "../../assets/icons/appIcons/campsite.svg"
import cbIcon from "../../assets/icons/appIcons/png/campbrain.png"

const applicationsLists = applications.map((r, i) => {
  r.image = [
    sbmIcon,
    cmIcon,
    gsIcon,
    cmoIcon,
    hubspotIcon,
    ccIcon,
    mailchimpIcon,
    reIcon,
    smIcon,
    mcaIcon,
    csIcon,
    cbIcon
  ][i]
  return r
})

export const Applications = () => {
  return (
    <div className="appGrid">
      {applicationsLists.map((application, index) => (
        <div key={index + application?.id} className="application">
          <img src={application.image} className="appIcon" />
          <p className="appName">{application.name}</p>
          {application.name === "Camper Machine" ? <p className="optionalText">Powered by Constant Contact</p> : null}
        </div>
      ))}
    </div>
  )
}
