import React from "react"
import "../../styles/components/_integrationItem.scss"

export default function IntegrationItem(payload) {
  const data = payload.data
  return (
    <div className="integration-item">
      <div className="integration-title-container">
        <h2>
          {`0${data.id}`} {data.itemTitle}
        </h2>
        <p>{data.itemDescription}</p>
        {data.blogLink && (
          <a className="readMoreLink" target="_blank" href={data.blogLink} rel="noreferrer">
            Read More &#8594;
          </a>
        )}
      </div>
      <div className="example-use-case-container">
        <h4 className="item-title">{data.cases.caseTitle}</h4>
        <div className="item-card-container">
          {data.cases.caseItems.map(caseItem => {
            return (
              <div className="item-card" key={caseItem}>
                <img src={caseItem.logo} className="caseIcon" />
                <div className="description">{caseItem.description}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
