import React from "react"
import PropTypes from "prop-types"
import Navbar from "../Navbar/Navbar"
import "../../styles/root.scss"
import Footer from "../Footer/Footer"

export default function FullWidthLayout({ children = null }) {
  return (
    <div className="fullWidthLayout">
      <Navbar />
      <div className="containerbox">{children}</div>
      <Footer />
    </div>
  )
}

FullWidthLayout.propTypes = {
  children: PropTypes.node
}
