import React from "react"
import Layout from "./../components/Common/Layout"

const NotFound = () => {
  return (
    <Layout>
      {/* <NotFoundContent /> */}
      Not Found
    </Layout>
  )
}

export default NotFound
