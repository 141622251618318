import React from "react"
import WaitlistLayout from "../components/Common/WaitlistLayout"
import CampminderForm from "../components/WaitlistForm/CampminderForm"

const WaitlistPage = () => {
  return (
    <WaitlistLayout>
      <CampminderForm withLogo={true} />
    </WaitlistLayout>
  )
}

export default WaitlistPage
