import { Link } from "react-router-dom"
import React from "react"
import upArrow from "../../assets/icons/scrollToTop.svg"
import featuredBlogImage from "../../assets/images/blog-image.png"
import facebook from "../../assets/icons/socials/faceboook.svg"
import instagram from "../../assets/icons/socials/instagram.svg"
import linkedin from "../../assets/icons/socials/linkedin.svg"
import twitter from "../../assets/icons/socials/twitter.svg"
import company from "../../assets/icons/company.svg"
import email from "../../assets/icons/email-white.svg"
import calendar from "../../assets/icons/calendar.svg"
import logo from "../../assets/icons/logo-full-light.svg"

export default function Footer() {
  const scrollToTop = () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div className="Footer--Root">
      <div className="footer__container">
        <div className="footerHeaderContent">
          <div className="blogSection">
            <h2 className="title">Featured Blog Post</h2>
            <a className="row" href="https://blog.thecampstack.com/integrating-campminder-with-school-bus-manager/">
              <img className="blogImage" src={featuredBlogImage} />
              <div className="column">
                <h3 className="blogTitle">Integrating CampMinder with School Bus Manager</h3>
                <p className="blogDescription">
                  By connecting CampMinder with School Bus Manager, we ensure that the hassle of manual data entry is
                  eliminated. Read More
                </p>
                <p className="readMore">Read More &#8594;</p>
              </div>
            </a>
          </div>
          <div className="links_block">
            <div className="social">
              <p className="link__title">Follow Us</p>
              <div className="row">
                <a href={"https://www.facebook.com/thecampstack"} target="_blank" rel="noopener noreferrer">
                  <img className="socialIcon" src={facebook} />
                </a>
                <a href={"https://www.instagram.com/officialcampstack"} target="_blank" rel="noopener noreferrer">
                  <img className="socialIcon" src={instagram} />
                </a>
                <a href={"https://www.linkedin.com/company/thecampstack"} target="_blank" rel="noopener noreferrer">
                  <img className="socialIcon" src={linkedin} />
                </a>
                <a href={"https://www.x.com/campstack"} target="_blank" rel="noopener noreferrer">
                  <img className="socialIcon" src={twitter} />
                </a>
              </div>
            </div>
            <div className="contact">
              <p className="link__title">Contact Us</p>
              <div className="column">
                <div className="row">
                  <img className="socialIcon" src={company} />
                  <p className="value">Los Angeles, California</p>
                </div>
                <div className="row">
                  <img className="socialIcon" src={email} />
                  <a href={"mailto:support@thecampstack.com"} className="value">
                    support@thecampstack.com
                  </a>
                </div>
                <div className="row">
                  <img className="socialIcon" src={calendar} />
                  <Link to={"/contact"} className="value">
                    Book an Appointment
                  </Link>
                </div>
              </div>
              {/* <a
                  href={"https://cal.services/eric/1hr%3A-Zoom-Video-Call/nVdOyS4gb"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact
                </a> */}
            </div>
          </div>
        </div>
        <div className="copy_rights">
          <img className="logo" src={logo} />
          <p>Copyright © The Camp Stack 2024. All Rights Reserved.</p>
        </div>
      </div>
      <button type="button" className="scrollToTop" onClick={scrollToTop}>
        <img src={upArrow} className="icon" alt="^" />
      </button>
    </div>
  )
}
