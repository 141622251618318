import React, { useEffect, useRef, useState } from "react"
import Arrow from "../../assets/images/accordionIcon.svg"
import "../../styles/components/_accordian.scss"

export default function Accordion(data) {
  const { title, content, open } = data
  const [isActive, setIsActive] = useState(false)
  const contentRef = useRef(null) // Reference to the content div

  useEffect(() => {
    if (open) {
      setIsActive(true)
    }
  }, [])

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={() => setIsActive(!isActive)}>
        <h1 className="accordion-title">{title}</h1>
        <img src={Arrow} className={`accordionIcon ${isActive && "activeAccordion"}`} />
      </div>
      <div
        ref={contentRef}
        className="accordion-content"
        style={{
          maxHeight: isActive ? `${contentRef.current.scrollHeight}px` : "0px"
        }}
      >
        <p>{content}</p>
      </div>
    </div>
  )
}
