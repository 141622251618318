/* eslint-disable react/prop-types */
import React, { useState } from "react"
import logo from "../../assets/icons/logo-full-dark.svg"
import formAbsoluteImage from "../../assets/images/landingBackground3.svg"

import user from "../../assets/icons/user.svg"
import camp from "../../assets/icons/camp.svg"
import emailIcon from "../../assets/icons/email.svg"
import phone from "../../assets/icons/phone.svg"
import { API } from "../../api/API"

const WaitlistForm = ({ withLogo }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [campName, setCampName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isUserWaitlisted, setUserWaitlisted] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const isValid = () => {
    if (firstName.length && lastName.length && campName.length && email.length && phoneNumber.length) {
      if (isValidEmail(email)) {
        return true
      } else {
        setErrorMessage("Please enter a valid email address.")
        return false
      }
    } else {
      setErrorMessage("Please fill out all the fields and try again.")
      return false
    }
  }

  const clearInputFields = () => {
    setFirstName("")
    setLastName("")
    setCampName("")
    setEmail("")
    setPhoneNumber("")
  }

  const submitHandler = async e => {
    e.preventDefault()

    const lead = {
      firstName,
      lastName,
      campName,
      email,
      phoneNumber
    }

    if (isValid()) {
      setLoading(true)
      await API.submitWaitlist(lead)
        .then(() => {
          setErrorMessage("Congratulations! We'll get in touch with you soon.")
          clearInputFields()
          setLoading(false)
          setUserWaitlisted(true)
        })
        .catch(err => {
          setErrorMessage("Oops, some error occurred. Please try again later.")
          console.log(err)
          clearInputFields()
          setLoading(false)
        })
    }
  }

  return (
    <form className="form">
      {withLogo ? (
        <div className="logoArea">
          <img src={logo} className="logo" />
        </div>
      ) : (
        <img src={formAbsoluteImage} className="landingBackground3" />
      )}
      <h3 className="formHeader">Learn More</h3>
      <div className="inputArea">
        <input
          type="text"
          value={firstName}
          className="inputField"
          placeholder="First Name"
          onChange={e => setFirstName(e.target.value)}
        />
        <img src={user} className="icon" />
      </div>
      <div className="inputArea">
        <input
          type="text"
          value={lastName}
          className="inputField"
          placeholder="Last Name"
          onChange={e => setLastName(e.target.value)}
        />
        <img src={user} className="icon" />
      </div>
      <div className="inputArea">
        <input
          type="text"
          value={campName}
          className="inputField"
          placeholder="Camp Name"
          onChange={e => setCampName(e.target.value)}
        />
        <img src={camp} className="icon" />
      </div>
      <div className="inputArea">
        <input
          type="email"
          value={email}
          className="inputField"
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
        />
        <img src={emailIcon} className="icon" />
      </div>
      <div className="inputArea">
        <input
          type="phone"
          value={phoneNumber}
          className="inputField"
          placeholder="Mobile Number"
          onChange={e => setPhoneNumber(e.target.value)}
        />
        <img src={phone} className="icon" />
      </div>
      <button
        type="submit"
        className="primaryButton"
        onClick={e => submitHandler(e)}
        disabled={isUserWaitlisted || isLoading}
      >
        Learn More
      </button>
      <p className="errorMessage">{errorMessage}</p>
      <div className="alignCenter">
        <div className="grayText">#1 Integration Solution For Camps.</div>
      </div>
    </form>
  )
}

export default WaitlistForm
