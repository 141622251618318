// this is to make sure we are not missing any variables from .env

const config = {
  version: process.env.REACT_APP_VERSION || process.env.npm_package_version || "",
  env: process.env.REACT_APP_ENV || "local",
  apiUrl: process.env.REACT_APP_API_URL || "http://localhost:8080/",
  appUrl: process.env.REACT_APP_URL || "http://localhost:3002/",
  nodePath: process.env.NODE_PATH,
  makeAppUrl: process.env.REACT_APP_MAKE_APP_URL || "http://localhost:3002/",
  sendinBlue: {
    apiKey: process.env.REACT_APP_SENDIN_BLUE_API_KEY,
    // this should be comma seperated in .env, if more than one id
    listIds: process.env.REACT_APP_SENDIN_BLUE_LIST_IDS
      ? process.env.REACT_APP_SENDIN_BLUE_LIST_IDS.split(",").map(id => Number(id))
      : []
  },
  domain: process.env.DOMAIN
}

module.exports.config = config
