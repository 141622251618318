import React from "react"
import WaitlistLayout from "../components/Common/WaitlistLayout"
import MsmForm from "../components/WaitlistForm/MsmForm"

const MsmPage = () => {
  return (
    <WaitlistLayout>
      <MsmForm withLogo={true} />
    </WaitlistLayout>
  )
}

export default MsmPage
