/* eslint-disable no-undef */
import React, { useEffect } from "react"
import FullWidthLayout from "../components/Common/FullWidthLayout"
import homepageLandingImage from "../assets/images/landingImage.jpg"
import plusIcon from "../assets/icons/plus_rounded.svg"
import createWorkflowImage from "../assets/images/createWorkflow.jpg"

import { Testimonials } from "../components/Testimonial/Testimonial"
import { Applications } from "../components/Applications/Applications"

import { data } from "../data/data"
import IntegrationItem from "../components/IntegrationItem/IntegrationItem"
import Accordian from "../components/Common/Accordian"
import { Link } from "react-router-dom"
import { config } from "../config/index"

export default function Home() {
  useEffect(() => {
    try {
      document.body.scrollTo({ top: 0, behavior: "smooth" })
    } catch (err) {
      console.error(err)
    }
  }, [])

  const { Integrations, pricing, Team, FAQ } = data

  return (
    <FullWidthLayout>
      <div className="Home--Root">
        <div className="headerContainer">
          <div className="content">
            <div className="textArea">
              {/* TODO: update this text later, Comment this for now */}
              {/* <h4 className="subtitle">Customized For You. Done For You.</h4> */}
              <h1 className="title">Integrating and Connecting All Your Favorite Camp Software.</h1>
              <a className="primaryButton" href={`${config.appUrl}/contact`} target="_blank" rel="noreferrer">
                Request a Demo
              </a>
            </div>
            {/* TODO: Update this image,
              1. Add new tile as more apps coming or add any other app tile to fill the empty space
              2. Change Camp Everwood to "Your Summer Camp"
            */}
            <img src={homepageLandingImage} className="homepageLanding" />
          </div>
        </div>
        <Testimonials />
        <section className="section2" id="apps">
          <div className="content">
            <h3 className="sectionTitle">Integrated Camp Software</h3>
            <p className="sectionSubtitle">List of all the camp software that we integrate within the Camp Stack</p>
            <Applications />
          </div>
        </section>
        {/* Integrations */}
        <section className="section2" id="features">
          <div className="content">
            <h3 className="sectionTitle">{Integrations.title}</h3>
            <p className="sectionSubtitle">{Integrations.subtitle}</p>
            {Integrations.items.map(item => (
              <IntegrationItem data={item} key={item.id} />
            ))}
          </div>
        </section>
        {/* Pricing */}
        <section className="section3" id="pricing">
          <div className="section-container">
            <div className="content">
              <h3 className="sectionTitle">{pricing.title}</h3>
              <div className="subsection-container">
                <div className="subsection">
                  <h1>{pricing.prices[0].amount}</h1>
                  <p>{pricing.prices[0].description}</p>
                </div>
                <img src={plusIcon} />
                <div className="subsection">
                  <h1>{pricing.prices[1].amount}</h1>
                  <p>{pricing.prices[1].description}</p>
                </div>
              </div>
              <hr />
              <div className="list-item-container">
                {pricing.listDescription.map((listItem, index) => (
                  <div key={index} className="list-item">
                    <img src={listItem.listIcon} />
                    <div className="list-item-text">
                      {listItem.isFirst ? (
                        <>
                          <strong style={{ marginRight: "3px" }}>{listItem.boldItem}</strong>
                          <p>{listItem.text}</p>
                        </>
                      ) : (
                        <>
                          <p>{listItem.text}</p>
                          <strong style={{ marginLeft: "3px" }}>{listItem.boldItem}</strong>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <a className="demoButtonLink" href={`${config.appUrl}/contact`} target="_blank" rel="noreferrer">
                {pricing.button.text}
              </a>
            </div>
            <img src={createWorkflowImage} className="createWorkflow-image" />
          </div>
        </section>
        {/* Team */}
        <section className="section4" id="about">
          <div className="content">
            <div className="content-container">
              <h3 className="sectionTitle">{Team.title}</h3>
              <p className="sectionSubtitle">{Team.subTitle}</p>
            </div>
            <div className="teamsGrid">
              {Team.members.map(member => {
                return (
                  <div className="memberItem" key={member.id}>
                    <img src={member.image} className="image" />
                    <div className="memberName">{member.name}</div>
                    <div className="memberJob">{member.job}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        {/* Add FAQ Below */}
        <section className="section7" id="faq">
          <h1 className="title">{FAQ.title}</h1>
          <div className="accordion">
            {FAQ.items.map((item, ind) => {
              return <Accordian title={item.title} content={item.description} key={ind} />
            })}
          </div>
        </section>
      </div>
    </FullWidthLayout>
  )
}
